<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialog"
      width="800"
      overlay-color="white"
      overlay-opacity="0.8"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-large rounded v-bind="attrs" v-on="on" :color="ButtonColor">
          {{ ButtonText }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          <span class="close" @click="close">X</span>
        </v-card-title>
        <iframe
          :src="askUrl"
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          width="100%"
          height="650px"
          style="border: none; border-radius: 10px"
        >
        </iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AskDialog",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    askUrl() {
      return `https://ask.levelup.ee/fdfvuo5b1#contact_email=${this.$store.state.user.email}`;
    },
  },
  props: {
    DialogOpen: Boolean,
    ButtonText: String,
    ButtonColor: String,
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  padding: 20px;
  background-color: rgb(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  border-radius: 10px !important;
}
.v-btn {
  margin: 20px;
  font-size: 12px;
  text-transform: capitalize;
}
.close {
  cursor: pointer;
  margin-left: 95%;
}
</style>
