<template>
    <star-rating
        :star-size="15"
        :key="ratingKey"
        :glow="1"
        :padding="10"
        active-color="#666666"
        :rating="candidateRating"
        :show-rating="false"
        @rating-selected="
            addRating($event, candidateRating, candidateId)
        "
    ></star-rating> 
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import axios from "axios";

export default {
    name: 'Rating',
    props: {
        candidateRating: Number,
        candidateId: String
    },
    data() {
      return {
        ratingKey: 0
      };
    },
    computed: {
        ...mapGetters(['ratingCounts'])
    },
    methods: {
        ...mapActions(['startAnimation']),
        async addRating(rating, currentRating, id) {
            try {
                let ratingCount = null;
                if (this.ratingCounts > 0) {
                    ratingCount = this.ratingCounts;
                } else {
                ratingCount = 0;
                }
                ratingCount += 1;
                this.$store.state.user.ratingCounts += 1;
                // if (this.ratingCounts % 5 === 0) {
                //     this.rCounter = 1;
                // } else {
                //     this.rCounter = 0;
                // }
                let value2 = rating;
                if (rating == currentRating) {
                    let input = {
                        rating: 0,
                        ratingCounts: ratingCount,
                        email: this.$store.state.user.email,
                    };
                    await axios.put("/api/form/rating/" + id, input);
                    this.$store.state.candidates.find((c) => c._id == id).rating = 0;
                    this.ratingKey += 1;
                } else {
                let input = {
                    rating: value2,
                    ratingCounts: ratingCount,
                    email: this.$store.state.user.email,
                };
                await axios.put("/api/form/rating/" + id, input);
                this.$store.state.candidates.find((c) => c._id == id).rating = value2;
                }
                 const randomNr = Math.floor(Math.random() * 4) + 1;
                if(randomNr === 1){
                    this.startAnimation(Math.floor(Math.random() * 3) + 1);
                }
                } catch (error) {
                    console.log(error);
                }
            },
        }
}
</script>

<style lang="scss">
.vue-star-rating{
    justify-content: center;
}
</style>