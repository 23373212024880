<template>
  <v-menu transition="scroll-x-reverse-transition">
    <template v-slot:activator="{ on }">
      <v-icon
        medium
        style="float: right; "
        @click="markedCandidate = id"
        v-on="on"
        >mdi-dots-vertical</v-icon
      >
    </template>

    <v-list dense nav>
      <v-list-item @click="markNotified()" v-if="!notified">
        <v-list-item-content v-text="optionsMarkMail"></v-list-item-content>
      </v-list-item>
      <v-list-item @click="unNotify()" v-if="notified">
        <v-list-item-content v-text="optionsUnmarkMail"></v-list-item-content>
      </v-list-item>
      <v-list-item @click="candidateDeletion(id)">
        <v-list-item-content>Delete</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "CardActions",
  data() {
    return {
      optionsMarkMail: "Mark e-mail sent",
      optionsUnmarkMail: "Unmark e-mail sent",
      optionsRemoveMail: "Unmark e-mail sent",
      unnotifiedCandidates: null,
      dialog: false,
    };
  },
  props: {
    id: String,
    notified: Boolean,
  },
  methods: {
    ...mapActions([
      "changeCandidateStatus",
      "deleteCandidate",
      "fetchCandidates",
    ]),
    async unNotify() {
      await axios.put(`/api/form/markNotified/${this.markedCandidate}`, {
        notified: false,
      });
      this.loadCandidates();
    },
    candidateDeletion(id) {
      this.deleteCandidate(id);
      this.loadCandidates();
    },
    async markNotified() {
      await axios.put(`/api/form/markNotified/${this.markedCandidate}`, {
        notified: true,
      });
      await axios.post("/api/form/addMessage/" + this.id, {
        author: this.$store.state.user.email,
        message: `Email sent ${new Date().toISOString().slice(0, 10)}`,
      });
      this.loadCandidates();
    },
    loadCandidates() {
      this.fetchCandidates(this.$route.params.id || this.defaultCampaign);
    },
  },
};
</script>
