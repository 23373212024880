<template>
  <v-container fluid style="margin-top:200px;">
    <h3 style="margin: auto">No new candidates, check back later!</h3>
    <div>
      <v-row justify="center" class="no-candidate-buttons">
        <ask-dialog
          :ButtonText="'Get more Candidates!'"
          :ButtonColor="'#fff'"
        />
        <ask-dialog
          :ButtonText="'Or hire new profile?'"
          :ButtonColor="'#CBD5DF'"
        />
      </v-row>
    </div>
    <div class="sad-lottie-div">
      <lottie-animation
        ref="anim"
        :animationData="require('@/lotties/unicorn.json')"
        :loop="true"
      ></lottie-animation>
    </div>
  </v-container>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import AskDialog from "./AskDialog.vue";

export default {
  name: "NoCandidates",
  components: { LottieAnimation, AskDialog },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.sad-lottie-div {
  width: 150px;
  margin: 0 auto;
}
.no-candidate-buttons {
  margin-top: 2rem;
  .v-btn {
    box-shadow: 0px 5px 15px #0000000f;
    margin: 20px;
  }
  .v-btn:nth-child(1) {
    color: #666666;
  }
  .v-btn:nth-child(2) {
    color: #ffffff;
  }
}
h3 {
  font-size: 18px;
  font-weight: normal;
  color: #666666;
}
</style>
